<template>
  <div>
    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1">Sign In</h3>
        <p class="text-muted font-weight-semi-bold">
          Enter your username and password
        </p>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>

        <b-form-group
          id="example-input-group-1"
          label="Username"
          label-for="example-input-1"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            id="example-input-1"
            name="example-input-1"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Username is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="example-input-group-2"
          label="Password"
          label-for="example-input-2"
          v-if="new_pass_required == false"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="example-input-2"
            name="example-input-2"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            Password is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="example-input-group-3"
          label="New Password"
          label-for="example-input-3"
          v-if="new_pass_required == true"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="example-input-3"
            name="example-input-3"
            v-model="$v.form.new_password.$model"
            :state="validateState('new_password')"
            aria-describedby="input-3-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-3-live-feedback">
            New password is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
          <button
            ref="kt_login_signin_submit"
            class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
          >
            Sign In
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import {
  LOGIN,
  LOGOUT,
  CHANGE_PASSWORD
} from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import {
  email,
  minLength,
  required,
  requiredIf
} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      // Remove this dummy login info
      new_pass_required: false,
      userPromise: [],
      form: {
        email: "",
        password: "",
        new_password: ""
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      },
      new_password: {
        required: requiredIf(function() {
          return this.new_pass_required == true;
        }),
        minLength: minLength(8)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
        new_password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const username = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      if (this.new_pass_required == true) {
        const new_password = this.$v.form.new_password.$model;
        this.changePassword(new_password);
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(LOGIN, { username, password })
        // go to which page after successfully login
        .then(user => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );

          // Check if its first time Login with temp
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            this.new_pass_required = true;
            this.userPromise = user;
          } else {
            // go to which page after successfully login
            this.$router.push({ name: "questionnaire-question-list" });
          }
        })
        .catch(error => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          alert(error.message);
        });
    },
    changePassword: function(new_password) {
      const userPromise = this.userPromise;

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(CHANGE_PASSWORD, { userPromise, new_password })
        .then(() => {
          this.$store
            .dispatch(LOGOUT)
            .then(() =>
              this.$router.push({ name: "questionnaire-question-list" })
            );
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        })
        .catch(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          //alert(error.message);
        });
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
